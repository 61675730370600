<template>
  <div class="result-by-cluster-container">
    <div v-if="distribution !== 'groups' && group === 'cluster'" class="txt-14 txt-font-din-medium txt-black w50 cluster-subtitle">
        {{ $t('components.description.testIfCasesAreRandomlyDistributed') }}
    </div>
    <div v-if="isAgeMuac" class="flex">
      <div class="w50">
        <AgeMuacResultsItem
          v-if="tables && tables.msd && tables.msd.tab1"
          :title="$t('components.titles.muacGreaterOrLessThan', {
            symbol: '<',
            value: muacLwr,
            metric: $t('values.mm')
          })"
          :msd="tables.msd.tab1"/>
        <AgeMuacResultsItem
          v-if="tables && tables.msd && tables.msd.tab2"
          :title="String.prototype.concat($t('components.titles.muacGreaterOrLessThan', {
            symbol: '>=',
            value: muacLwr,
            metric: $t('values.mm')
          }), ', ', $t('components.titles.muacGreaterOrLessThan', {
            symbol: '<',
            value: muacUpr,
            metric: $t('values.mm')
          }))"
          :msd="tables.msd.tab2"/>
      </div>
      <div class="w50 ml20">
        <AgeMuacResultsItem
          v-if="tables && tables.msd && tables.msd.tab3"
          :title="$t('components.titles.muacGreaterOrLessThan', {
            symbol: '>=',
            value: muacUpr,
            metric: $t('values.mm')
          })"
          :msd="tables.msd.tab3"/>
      </div>
    </div>
    <div v-else class="flex">
      <div v-if="group === 'cluster' || group === 'months'" class="w50">
        <div v-if="distribution === 'groups'">
          <ResultTable
            v-if="tables && tables.msd && tables.msd.length > 0"
            type="msd"
            :group="group"
            :filter-type="type"
            :table="tables.msd"/>
          <a-empty
            v-if="!tables || tables.msd && tables.msd.length === 0"
            :description="$t('components.description.notEnoughDataToShowResults', {
              value: group === 'cluster' ? $tc('values.cluster', 2) : $t('values.age')
            })"/>
        </div>
        <div v-else-if="group === 'cluster'">
          <ResultTable
            v-if="tables && tables.iod && tables.iod.length > 0"
            type="iod"
            :group="group"
            :filter-type="type"
            :table="tables.iod"/>
        </div>
      </div>
      <div class="w50 ml20">
        <div v-if="group === 'cluster' && distribution !== 'groups'">
          <ResultTable
            v-if="plot && plot.length > 0"
            type="caseDistribution"
            :group="group"
            :filter-type="type"
            :table="plot"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AnthropometryResultsTables',
  components: {
    ResultTable: () => import('../result-table'),
    AgeMuacResultsItem: () => import('./age-muac.vue')
  },
  props: {
    type: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
    distribution: {
      type: String,
      required: true
    },
    muacLwr: {
      type: [String, Number],
      required: false,
      default: ''
    },
    muacUpr: {
      type: [String, Number],
      required: false,
      default: ''
    },
    isAgeMuac: {
      type: Boolean,
      default: false
    },
    tables: {
      type: [Object, Array],
      default: null
    },
    plot: {
      type: Array,
      default: null
    }
  }
};
</script>

<style lang="scss">
.cluster-subtitle {
  margin-top: 14px;
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  font-family: 'DINNextLTPro-Regular';
  color: #363534;
}
.ml20 {
  margin-left: 20px;
}
.result-by-cluster-container {
  margin-top: 14px;
}
</style>
