var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"result-by-cluster-container"},[(_vm.distribution !== 'groups' && _vm.group === 'cluster')?_c('div',{staticClass:"txt-14 txt-font-din-medium txt-black w50 cluster-subtitle"},[_vm._v(" "+_vm._s(_vm.$t('components.description.testIfCasesAreRandomlyDistributed'))+" ")]):_vm._e(),(_vm.isAgeMuac)?_c('div',{staticClass:"flex"},[_c('div',{staticClass:"w50"},[(_vm.tables && _vm.tables.msd && _vm.tables.msd.tab1)?_c('AgeMuacResultsItem',{attrs:{"title":_vm.$t('components.titles.muacGreaterOrLessThan', {
          symbol: '<',
          value: _vm.muacLwr,
          metric: _vm.$t('values.mm')
        }),"msd":_vm.tables.msd.tab1}}):_vm._e(),(_vm.tables && _vm.tables.msd && _vm.tables.msd.tab2)?_c('AgeMuacResultsItem',{attrs:{"title":String.prototype.concat(_vm.$t('components.titles.muacGreaterOrLessThan', {
          symbol: '>=',
          value: _vm.muacLwr,
          metric: _vm.$t('values.mm')
        }), ', ', _vm.$t('components.titles.muacGreaterOrLessThan', {
          symbol: '<',
          value: _vm.muacUpr,
          metric: _vm.$t('values.mm')
        })),"msd":_vm.tables.msd.tab2}}):_vm._e()],1),_c('div',{staticClass:"w50 ml20"},[(_vm.tables && _vm.tables.msd && _vm.tables.msd.tab3)?_c('AgeMuacResultsItem',{attrs:{"title":_vm.$t('components.titles.muacGreaterOrLessThan', {
          symbol: '>=',
          value: _vm.muacUpr,
          metric: _vm.$t('values.mm')
        }),"msd":_vm.tables.msd.tab3}}):_vm._e()],1)]):_c('div',{staticClass:"flex"},[(_vm.group === 'cluster' || _vm.group === 'months')?_c('div',{staticClass:"w50"},[(_vm.distribution === 'groups')?_c('div',[(_vm.tables && _vm.tables.msd && _vm.tables.msd.length > 0)?_c('ResultTable',{attrs:{"type":"msd","group":_vm.group,"filter-type":_vm.type,"table":_vm.tables.msd}}):_vm._e(),(!_vm.tables || _vm.tables.msd && _vm.tables.msd.length === 0)?_c('a-empty',{attrs:{"description":_vm.$t('components.description.notEnoughDataToShowResults', {
            value: _vm.group === 'cluster' ? _vm.$tc('values.cluster', 2) : _vm.$t('values.age')
          })}}):_vm._e()],1):(_vm.group === 'cluster')?_c('div',[(_vm.tables && _vm.tables.iod && _vm.tables.iod.length > 0)?_c('ResultTable',{attrs:{"type":"iod","group":_vm.group,"filter-type":_vm.type,"table":_vm.tables.iod}}):_vm._e()],1):_vm._e()]):_vm._e(),_c('div',{staticClass:"w50 ml20"},[(_vm.group === 'cluster' && _vm.distribution !== 'groups')?_c('div',[(_vm.plot && _vm.plot.length > 0)?_c('ResultTable',{attrs:{"type":"caseDistribution","group":_vm.group,"filter-type":_vm.type,"table":_vm.plot}}):_vm._e()],1):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }